import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  SliderShow,
  SliderItems,
  FullScreenSliderItem,
} from "../../../components/slider/slider";
import loading from "../../../assets/loading.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  artistImageDetailedSliceData,
  getSingleClient,
  markUpdatesAsViewed,
} from "../../../AxiosFunctions/Axiosfunctionality";
import {
  addCart,
  saveCartItemMessageKey,
  getAnEstimateHandler,
} from "../../../redux/addToCart";

import MyPopup from "../../../components/myPopup/myPopup";
import BackIconForm from "../../../assets/svgs/backArrow.svg";
import TabPersonalWorks from "./tabPersonalWorks";
import TabProperties from "./tabProperties";
import { decodeToken } from "react-jwt";
import TabMotion from "./tabMotion";
import TabAwardPress from "./tabAwardPress";
import FavoriteButton from "../agentUser/favoriteButton";
import AddToMoodBoardButton from "../agentUser/addToMoodBoardButton";
import ClientFavoriteButton from "../clientUser/favoriteButton";
import ClientAddToMoodBoardButton from "../clientUser/addToMoodBoardButton";
import { AgentDataAPI as AgentDataAPIFunc } from "../../../redux/agentSlice";

function SearchByArtist(props) {
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [fullscreen, setFullscreen] = useState({ screen: false, route: null });
  const [fullScreenData, setFullScreenData] = useState({
    screen: false,
    route: null,
  });
  const { pages } = useParams();
  const { search } = useParams();

  const searchValue = search || "portfolio";
  const [seePortfolioImages, setSeePortfolioImages] = useState(false);
  const { AddToCart, ArtistDataAPI, AgentDataAPI, ClientDataAPI } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  const [data1, setData1] = useState(null);
  const [dataViewed, setDataViewed] = useState({});
  const [similarData, setSimilarData] = useState({});
  const [artistImages, setArtistImages] = useState(8);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [isPopupShowWithCheckbox, setIsPopupShowWithCheckbox] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [msg, setMsg] = useState("");
  const [, setartistKSOrder] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [displayedImages, setDisplayedImages] = useState([]);
  const [imageIndexDisplayed, setImageIndexDisplayed] = useState(true);
  const [seeFullPorfolio, setSeeFullPorfolio] = useState(false);
  const [fullPorfolio, setFullPorfolio] = useState([]);
  const [searchPorfolio, setSeachPorfolio] = useState([]);
  const [videoModal, setVideoModal] = useState(false);
  const [pwModal, setPwModal] = useState(false);
  const [carrouselIndex, setCarrouselIndex] = useState(0);

  const [pwBookPublishing, setPwBookPublishing] = useState([]);
  const [allPws, setAllPws] = useState([]);
  const [pwMedical, setPwMedical] = useState([]);
  const [pwAdvertising, setPwAdvertising] = useState([]);
  const [pwEditorial, setPwEditorial] = useState([]);
  const [pwEntertaiment, setPwEntertaiment] = useState([]);
  const [agentInfo, setAgentInfo] = useState();
  const [subPictureBook, setSubPictureBook] = useState([]);
  const [subMiddleGrade, setSubMiddleGrade] = useState([]);
  const [subYoungAdult, setSubYoungAdult] = useState([]);
  const [subGraphicNovel, setSubGraphicNovel] = useState([]);
  const [subBoardBook, setSubBoardBook] = useState([]);
  const [subAdultPublishing, setSubAdultPublishing] = useState([]);
  const [subNovelty, setSubNovelty] = useState([]);
  const [subBoxSet, setSubBoxSet] = useState([]);
  const [subGame, setSubGame] = useState([]);
  const [subSelfHelp, setSubSelfHelp] = useState([]);
  const [clientDetails, setClientDetails] = useState();
  const [token, setToken] = useState(localStorage.getItem("authorization"));
  const [pwOther, setpwOther] = useState([]);
  const [pwLevel, setPwLevel] = useState(1);
  const [currentPw, setCurrentPw] = useState();

  const myStateRef = useRef(0);
  const [tabsInArtistPortfolio, setTabsInArtistPortfolio] = useState([
    "Portfolio",
    "Bio",
    "KidShannon Portfolio",
  ]);
  const fullscreenCondRef = useRef({
    full: false,
    imageInd: 0,
  });
  const queryParams = new URLSearchParams(history.location.search);
  const image = queryParams.get("image");
  const imageURL = queryParams.get("imageURL");

  const id = queryParams.get("id");

  const fullscreenCond = queryParams.get("fullscreen");

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  useEffect(() => {
    setAgentInfo(AgentDataAPI?.agentData);
  }, [AgentDataAPI]);

  useEffect(() => {
    const fetchData = async () => {
      // Retrieve the token from localStorage

      if (token) {
        // Decode the token
        const details = decodeToken(token);

        if (details && details._id) {
          try {
            // Fetch the artist details
            const response = await getSingleClient({ _id: details._id });
            if (response === "NO CLIENT FOUND") {
              setClientDetails(null);
            } else {
              setClientDetails(response);
            }
          } catch (error) {
            // Handle any errors, such as network issues or decoding problems
            setClientDetails(null);
            console.error("Error fetching artist details:", error);
          }
        }
      } else {
        setClientDetails(null);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    const handleStorageChange = () => {
      const storedToken = localStorage.getItem("authorization");
      if (storedToken !== token) {
        setToken(storedToken);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [token]);

  useEffect(() => {
    let newTabsInPortfolio = ["Portfolio", "Bio"];

    if (data1 && data1[pages]?.publishedWorks?.length !== 0) {
      newTabsInPortfolio.push("Published Works");
      setAllPws(data1[pages].publishedWorks);

      const filteredPWBookPublishing = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "publishing")
        .reverse();
      const filteredPWAdvertising = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "advertising")
        .reverse();
      const filteredPWEditorial = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "editorial")
        .reverse();
      const filteredPWMedical = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "medical")
        .reverse();
      const filteredPWEntertaiment = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "entertainment")
        .reverse();
      const filteredPWOther = data1[pages].publishedWorks
        .filter((pw) => pw.categoryPW === "other" || pw.categoryPW === "")
        .reverse();

      const filteredPWSubPictureBook = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "pictureBook")
        .reverse();
      const filteredPWSubMiddleGrade = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "middleGrade")
        .reverse();
      const filteredPWSubYoungAdult = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "youngAdult")
        .reverse();
      const filteredPWSubGraphicNovel = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "graphicNovel")
        .reverse();
      const filteredPWSubBoardBook = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "boardBook")
        .reverse();
      const filteredPWSubAdultPublishing = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "adultPublishing")
        .reverse();
      const filteredPWSubNovelty = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "novelty")
        .reverse();
      const filteredPWSubBoxSet = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "boxSet")
        .reverse();
      const filteredPWSubGame = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "game")
        .reverse();
      const filteredPWSubSelfHelp = data1[pages].publishedWorks
        .filter((pw) => pw.subCategoryPW === "selfHelp")
        .reverse();

      setPwBookPublishing(filteredPWBookPublishing);
      setPwAdvertising(filteredPWAdvertising);
      setPwEditorial(filteredPWEditorial);
      setPwMedical(filteredPWMedical);
      setPwEntertaiment(filteredPWEntertaiment);
      setpwOther(filteredPWOther);
      setSubPictureBook(filteredPWSubPictureBook);
      setSubMiddleGrade(filteredPWSubMiddleGrade);
      setSubYoungAdult(filteredPWSubYoungAdult);
      setSubGraphicNovel(filteredPWSubGraphicNovel);
      setSubBoardBook(filteredPWSubBoardBook);
      setSubAdultPublishing(filteredPWSubAdultPublishing);
      setSubNovelty(filteredPWSubNovelty);
      setSubBoxSet(filteredPWSubBoxSet);
      setSubGame(filteredPWSubGame);
      setSubSelfHelp(filteredPWSubSelfHelp);
    }
    if (data1 && data1[pages]?.personalWorks?.length !== 0) {
      newTabsInPortfolio.push("Personal Works");
    }

    if (
      data1 &&
      data1[pages]?.properties.filter((property) => property.status === "1")
        ?.length !== 0
    ) {
      newTabsInPortfolio.push("Properties");
    }

    if (data1 && data1[pages]?.motions.length !== 0) {
      newTabsInPortfolio.push("Motion");
    }

    if (data1 && data1[pages]?.awardPress.length !== 0) {
      newTabsInPortfolio.push("Awards & Press");
    }

    newTabsInPortfolio.push("KidShannon Portfolio");
    const removedDuplicateNewTabsinPortfolio = [...new Set(newTabsInPortfolio)];
    setTabsInArtistPortfolio(removedDuplicateNewTabsinPortfolio);
  }, [data1]);
  useEffect(() => {
    if (search === "publishedworks" && id && allPws.length > 0) {
      const selectedPw = allPws.find((pw) => pw.id === id);

      setCurrentPw(selectedPw);
      setPwLevel(2);
    } else {
      setPwLevel(1);
    }
  }, [id, allPws]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    let imageInd;

    if (image) {
      if (data1) {
        let routeTo;
        if (data1[pages]?.slideList.length < parseInt(image, 10)) {
          imageInd = data1[pages].slideList.length - 1;
          if (id) {
            routeTo = `/${pages}/${searchValue}?image=${imageInd}&id=${id}`;
          } else {
            routeTo = `/${pages}/${searchValue}?image=${imageInd}`;
          }
          history.replace(routeTo);
        } else {
          if (imageURL) {
            const slideList = data1[pages].slideList; // Get the slide list for the current page
            const imageIndex = slideList.findIndex(
              (slide) => slide === imageURL
            ); // Find index of the slide with the matching URL
            if (imageIndex !== -1) {
              imageInd = 0;
            } else {
              imageInd = imageIndex;
            }
          } else {
            imageInd = parseInt(image, 10);
          }
          if (id) {
            routeTo = `/${pages}/${searchValue}?image=0&id=${id}`;
          } else {
            routeTo = `/${pages}/${searchValue}?image=0`;
          }
          history.replace(routeTo);
        }
        let tempObj = { ...fullScreenData };
        tempObj.screen = fullscreenCond === "true" ? true : false;
        tempObj.route = data1[pages].slideList[imageInd];
        setFullscreen(tempObj);
        fullscreenCondRef.current.full = tempObj.screen;
        fullscreenCondRef.current.imageInd = parseInt(image, 10);
      }
    }

    myStateRef.current = imageInd;
    if (imageInd) {
      setSliderIndex(parseInt(imageInd, 10));
    } else {
      setSliderIndex(0);
    }

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      window.removeEventListener("scroll", function () {});
    };
  }, [data1, image]);

  useEffect(() => {
    if (!fullscreenCond && data1) {
      let newPath;
      if (!image) {
        // Assuming 'pages' and 'search' are variables holding parts of the path,
        // and 'image' is a query param. Making sure the path starts with '/' to make it absolute.

        if (id) {
          newPath = `/${pages}/${searchValue}?image=0&id=${id}`;
        } else {
          if (imageURL && data1) {
            const slideList = data1[pages].slideList; // Get the slide list for the current page
            const imageIndex = slideList.findIndex(
              (slide) => slide === imageURL
            ); // Find index of the slide with the matching URL

            if (imageIndex !== -1) {
              newPath = `/${pages}/${searchValue}?image=${imageIndex}`;
              setSliderIndex(imageIndex);
            } else {
              newPath = `/${pages}/${searchValue}?image=0`;
            }
          } else {
            newPath = `/${pages}/${searchValue}?image=0`;
          }
        }
      } else {
        // Correcting the path to be absolute by ensuring it starts with '/'.
        if (id) {
          newPath = `/${pages}/${searchValue}?image=${image}&id=${id}`;
        } else {
          newPath = `/${pages}/${searchValue}?image=${image}`;
        }
      }
      history.replace(newPath);
    }
  }, [fullscreenCond, image, pages, searchValue, history, data1]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (history.action === "POP") {
        if (fullscreenCondRef.current.full) {
          let temp = { ...fullscreen };

          temp.resposive = windowSize.innerWidth < 479 ? true : false;
          temp.screen = false;
          fullscreenCondRef.current.full = false;
          fullscreenCondRef.current.imageInd = parseInt(image, 10);
          setFullscreen(temp);
        } else {
          // history.goBack();
        }
      }
    });

    // Return a cleanup function to remove the listener when the component unmounts.
    return () => {
      localStorage.removeItem("routePaths");
      unlisten();
    };
  }, [history]);

  const portfolioUpdate = async (categoryKeyword) => {
    setIsLoading(true);

    const tempData = await artistImageDetailedSliceData({
      fullName: pages,
      category: categoryKeyword,
    });

    const route = [
      { val: "SAHome", link: "./" },
      { val: "Categories", link: "./categories" },
      { val: categoryKeyword, link: "./categories/" + categoryKeyword },
      {
        val: fullPorfolio[pages].firstname + " " + fullPorfolio[pages].lastname,
        artistExist: true,
      },
    ];

    localStorage.setItem("routePaths", JSON.stringify(route));
    localStorage.setItem("Category", categoryKeyword);
    history.replace(`/${pages}/portfolio?image=0`);
    props.setCurrentCategory(categoryKeyword);
    setData1(tempData.activeArtist);
    setSeachPorfolio(tempData.activeArtist);
    setDisplayedImages(tempData.activeArtist[pages].subListData);
    setIsLoading(false);
  };

  const handleFilterChange = async (filter, updateView) => {
    if (agentInfo && agentInfo?._id && filter === "Bio" && updateView) {
      await markUpdatesAsViewed({
        agentId: agentInfo?._id,
        artistId: data1[pages].id,
        updateType: "bio",
      });
      await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
    }
    const urlFriendlyFilter = filter.replace(/\s+/g, "").toLowerCase();
    history.push(`/${pages}/${urlFriendlyFilter}?image=${image}`);
  };
  const viewPortfolioImage = async (index) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "portfolio",
      itemId: data1[pages].subListData[index],
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const viewPublishedWorks = async (itmeId) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "publishedWorks",
      itemId: itmeId,
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const viewPersonalWorks = async (itmeId) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "personalWorks",
      itemId: itmeId,
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const viewTabProperties = async (itmeId) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "properties",
      itemId: itmeId,
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const viewMotions = async (itmeId) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "motions",
      itemId: itmeId,
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const viewAwardPress = async (itmeId) => {
    await markUpdatesAsViewed({
      agentId: agentInfo?._id,
      artistId: data1[pages].id,
      updateType: "awardPress",
      itemId: itmeId,
    });
    await dispatch(AgentDataAPIFunc({ _id: agentInfo?._id }));
  };
  const addToCartArtist = (id, firstname, getAnEstimate = false) => {
    dispatch(addCart({ key: id, data: { id: id, Name: firstname } }));
    if (getAnEstimate) {
      dispatch(getAnEstimateHandler());
    }
  };

  const dataLocalArtist = (key, _id, firstname, bio, listData, subListData) => {
    let tempData = localStorage.getItem("artistViewed_V5");

    tempData = JSON.parse(tempData);
    if (tempData === null) {
      tempData = {};
      tempData[key] = {
        id: _id,
        title: firstname,
        detail: bio,
        slideList: listData,
      };
      localStorage.setItem("artistViewed_V5", JSON.stringify(tempData));
    } else {
      tempData[key] = {
        id: _id,
        title: firstname,
        detail: bio,
        slideList: listData,
        subListData: subListData,
      };

      let tempDataOnlySix = {};

      // Convert the object into an array of key-value pairs
      const entries = Object.entries(tempData);
      // Reverse the array
      const reversedEntries = entries.reverse();

      // Loop over the reversed array
      reversedEntries.forEach(([key, value], index) => {
        tempDataOnlySix[key] = value;
      });

      localStorage.setItem("artistViewed_V5", JSON.stringify(tempDataOnlySix));
    }
  };

  const getUserData = async () => {
    setIsLoading(true);
    let localPrevCate =
      localStorage.getItem("Category") === "Cgi" ||
      localStorage.getItem("Category") === "Motion"
        ? "3D Rendering"
        : localStorage.getItem("Category");
    localPrevCate = localPrevCate || "none";

    if (localPrevCate === "none") {
      if (localStorage.getItem("routePaths")) {
        if (JSON.parse(localStorage.getItem("routePaths"))[2]) {
          switch (JSON.parse(localStorage.getItem("routePaths"))[2].val) {
            case "Graphic Novel":
              if (JSON.parse(localStorage.getItem("routePaths")).length < 4) {
                localPrevCate = JSON.parse(
                  localStorage.getItem("routePaths")
                )[2].val;
              } else {
                localPrevCate = JSON.parse(
                  localStorage.getItem("routePaths")
                )[3].val.toUpperCase();
              }
              break;
            case "Bipoc":
              localPrevCate = "none";
              break;
            case "Medical":
              localPrevCate =
                JSON.parse(
                  localStorage.getItem("routePaths")
                )[3]?.val.toUpperCase() || "none";
              break;
            default:
              localPrevCate = "none";
          }
        }
      }
    }

    if (searchValue === "motion") {
      localPrevCate = "none";
    }

    let tempData = await artistImageDetailedSliceData({
      fullName: pages,
      category: localPrevCate,
    });
    let fullTempData = await artistImageDetailedSliceData({
      fullName: pages,
      category: "none",
    });

    if (localStorage.getItem("routePaths")) {
      let route = JSON.parse(localStorage.getItem("routePaths"));
      if (!route.find((obj) => obj.artistExist === true)) {
        route.push({
          val:
            tempData.activeArtist[pages].firstname +
            " " +
            tempData.activeArtist[pages].lastname,
          artistExist: true,
        });
        localStorage.setItem("routePaths", JSON.stringify(route));
      }
    }
    dataLocalArtist(
      tempData.activeArtist[pages].fullName,
      tempData.activeArtist[pages].id,
      tempData.activeArtist[pages].firstname +
        " " +
        tempData.activeArtist[pages].lastname,
      tempData.activeArtist[pages].detail,
      tempData.activeArtist[pages].slideList,
      tempData.activeArtist[pages].subListData
    );

    let tempImage = [];
    tempImage.push(tempData.activeArtist[pages].subListData[0]);

    setFullPorfolio(fullTempData.activeArtist);
    setSeachPorfolio(tempData.activeArtist);
    setDisplayedImages(tempImage);
    setSimilarData(tempData.similarArtist);
    setData1(tempData.activeArtist);
  };
  const setSliderIndexHandler = (
    keys,
    oldValue = null,
    clickedSliderButton = false
  ) => {
    if (clickedSliderButton) {
      myStateRef.current = keys;
      setSliderIndex(keys);
    } else {
      myStateRef.current = keys;
      setSliderIndex(keys);
    }
    let newPath;
    if (id) {
      newPath = `/${pages}/${searchValue}?image=${keys}&id=${id}`;
    } else {
      newPath = `/${pages}/${searchValue}?image=${keys}`;
    }
    history.replace(newPath);
  };

  useEffect(() => {
    let currentSelectedSlider = document.getElementById("firstSlider" + image);
    var prev = document.getElementsByClassName("sliderPrevArrow")[0];
    var next = document.getElementsByClassName("sliderNextArrow")[0];
    const handlePrevClick = (e) => {
      if (isNaN(myStateRef.current) || myStateRef.current === 0) {
        setSliderIndexHandler(
          data1[pages].pictureTitle.length - 1,
          myStateRef.current,
          true
        );
      } else {
        setSliderIndexHandler(myStateRef.current - 1, myStateRef.current, true);
      }
    };

    const handleNextClick = (e) => {
      if (
        isNaN(myStateRef.current) ||
        myStateRef.current === data1[pages].pictureTitle.length - 1
      ) {
        setSliderIndexHandler(0, data1[pages].pictureTitle.length - 1, true);
      } else {
        setSliderIndexHandler(myStateRef.current + 1, myStateRef.current, true);
      }
    };

    if (currentSelectedSlider) {
      currentSelectedSlider.style.boxShadow = "0 2px 10px #141c2a !important";
    }
    if (prev) {
      prev.addEventListener("click", handlePrevClick);
    }

    if (next) {
      next.addEventListener("click", handleNextClick);
    }

    // Cleanup function
    return () => {
      if (prev) {
        prev.removeEventListener("click", handlePrevClick);
      }
      if (next) {
        next.removeEventListener("click", handleNextClick);
      }
    };
  }, [data1, imageIndexDisplayed, pages, search]);

  useEffect(() => {
    setData1(null);
    getUserData();
    let artistKSOrderTemp =
      ArtistDataAPI.artistData.filter((artist) => artist._id === pages)[0]
        ?.orderKidArtist || 0;
    setartistKSOrder(artistKSOrderTemp);

    function getLocalStorage() {
      if (localStorage.getItem("artistViewed_V5") !== null) {
        setDataViewed(JSON.parse(localStorage.getItem("artistViewed_V5")));
      }
    }
    handleWindowResize();
    getLocalStorage();
    setIsLoading(false);
  }, [pages]);

  const setFullScreenHandler = (route, key) => {
    let temp = { ...fullscreen };

    if (!temp.screen && route) {
      temp.route = route;
      temp.key = key;
    }

    temp.resposive = windowSize.innerWidth < 479 ? true : false;
    temp.screen = !temp.screen;
    fullscreenCondRef.current.full = temp.screen;
    fullscreenCondRef.current.imageInd = parseInt(image, 10);
    setFullscreen(temp);
    setFullScreenData(data1[pages]);
    let newPath;
    if (temp.screen) {
      if (id) {
        newPath = `/${pages}/${searchValue}?image=${image}&id=${id}&fullscreen=true`;
      } else {
        newPath = `/${pages}/${searchValue}?image=${image}&fullscreen=true`;
      }
      history.replace(newPath);
    } else {
      if (id) {
        newPath = `/${pages}/${searchValue}?image=${image}&id=${id}`;
      } else {
        newPath = `/${pages}/${searchValue}?image=${image}`;
      }
      setImageIndexDisplayed(!imageIndexDisplayed);
      history.replace(newPath);
    }
  };
  const handleSlideChange = (index) => {
    setSliderIndexHandler(index, myStateRef.current, true);
    // You can also update other states or perform additional actions here
  };
  const saveCartMessage = (msg) => {
    dispatch(saveCartItemMessageKey({ messageShow: msg }));
  };

  const addToCartArtistHandler = (id, title, getAnEstimate = false) => {
    let key = Object.keys(AddToCart.cartInfo).find((element) => element === id);
    if (key === undefined) {
      if (AddToCart.cartInfo.messageShow) {
        setMsg(
          "You have added " +
            title +
            " to your list, to view your list visit Contact/My List Page."
        );
        setIsPopupShow(true);
      }
      addToCartArtist(id, title, getAnEstimate);
    } else {
      setMsg(
        "You have already added " +
          title +
          " to your list, to view your list visit Contact/My List Page."
      );
      setIsPopupShow(true);
      setIsPopupShowWithCheckbox(false);
    }
  };

  const onImageLoad = (index) => {
    if (
      index + 1 === displayedImages.length &&
      data1[pages].subListData.length !== index + 1
    ) {
      let tempImage = [...displayedImages];
      tempImage.push(data1[pages].subListData[index + 1]);
      setDisplayedImages(tempImage);
    }

    if (displayedImages.length === image + 1) {
      setImageIndexDisplayed(!imageIndexDisplayed);
    }
  };

  if (fullscreen.screen) {
    return (
      <FullScreenSliderItem
        onClick={setFullScreenHandler}
        currentData={fullScreenData}
        fullscreen={fullscreen}
      />
    );
  }

  if (isLoading) {
    return (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <img className="mb-3" alt="loading" src={loading} />
      </div>
    );
  }

  const mapBipocName = (bipocSubCategory) => {
    const bipocObj = {
      AsianArtist: "Asian",
      LatinoArtist: "Latino/Latina",
      CentralAsianArtist: "Central Asian",
      IndigenousArtist: "Indigenous",
    };
    return bipocObj[bipocSubCategory] || "";
  };

  const handleCarousel = (i) => {
    setPwModal(true);
    setCarrouselIndex(i);
  };

  const moveCarrousel = (moveTo) => {
    const newIndex = carrouselIndex + moveTo;
    if (newIndex >= currentPw.pwSecondaryImages.length) {
      setCarrouselIndex(0);
    } else if (newIndex < 0) {
      setCarrouselIndex(currentPw.pwSecondaryImages.length - 1);
    } else {
      setCarrouselIndex(newIndex);
    }
  };

  const handleCheckBox = (e) => {
    setIsLoading(true);

    // Update the state based on the previous state
    setSeeFullPorfolio((previous) => {
      const stateCheckbox = !previous;

      // Now use stateCheckbox in your conditionals
      if (stateCheckbox) {
        setData1(fullPorfolio);
        setDisplayedImages(fullPorfolio[pages].subListData);
      } else {
        setData1(searchPorfolio);
        setDisplayedImages(searchPorfolio[pages].subListData);
      }

      // Return the new state
      return stateCheckbox;
    });

    setImageIndexDisplayed((prev) => !prev);
    setIsLoading(false);
  };

  const getVideoID = (url) => {
    const id = url.split("/")[url.split("/").length - 1];
    return id;
  };

  const handlePWLevelChange = (pw) => {
    window.scrollTo(0, 0);
    history.push(`/${pages}/${search}?id=${pw.id}`);
    // setCurrentPw(pw);
  };

  const handleBackButtonPublishedWorks = () => {
    setPwLevel(1);
    history.push(`/${pages}/${searchValue}?image=${image}`);
  };

  return (
    <div style={{ width: "100%" }}>
      {data1 !== null ? (
        <div
          className={windowSize.innerWidth < 479 ? "" : "d-flex"}
          style={
            windowSize.innerWidth < 479
              ? { marginLeft: "8%" }
              : { justifyContent: "end", marginTop: "-10px" }
          }
        >
          <div
            className="d-flex flex-column"
            style={
              windowSize.innerWidth < 479
                ? {}
                : { justifyContent: "flex-start" } // Changed to flex-start for column alignment
            }
          >
            {windowSize.innerWidth > 479 && (
              <>
                <div
                  className="d-flex"
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <Link
                    to="/contact"
                    className={
                      windowSize.innerWidth < 479
                        ? "allArtistsButtons mb-4"
                        : "talentbutton"
                    }
                    style={{ flex: "1", marginRight: "10px", width: "100%" }} // Adjust flex and margin for even spacing
                    onClick={() =>
                      addToCartArtistHandler(
                        data1[pages].id,
                        data1[pages].title,
                        true
                      )
                    }
                  >
                    GET ESTIMATE
                  </Link>
                  {!agentInfo?._id && (
                    <Link
                      data-w-id="e04f643e-f302-16e2-74ee-4bc7e85391d8"
                      to="#"
                      className={
                        windowSize.innerWidth < 479
                          ? "allArtistsButtons mb-4"
                          : "talentbutton"
                      }
                      style={{ flex: "1", width: "100%" }} // Adjust flex to fill remaining space
                      onClick={() =>
                        addToCartArtistHandler(
                          data1[pages].id,
                          data1[pages].title
                        )
                      }
                    >
                      ADD TO MY LIST
                    </Link>
                  )}
                  {data1 && data1[pages] && (
                    <>
                      <FavoriteButton
                        agentData={AgentDataAPI?.agentData}
                        artistID={data1[pages].id}
                        windowSize={windowSize}
                      />
                      <ClientFavoriteButton
                        clientData={ClientDataAPI?.clientData}
                        artistID={data1[pages].id}
                        windowSize={windowSize}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className="row m-0 pt-0"
        style={{
          maxWidth: "100%",
          justifyContent: "space-around",
          margin: "0px",
        }}
      >
        {data1 !== null && data1[pages] ? (
          <>
            <div className="pl-2 left_content">{props.children}</div>
            <div className="row mid_full_content">
              <div style={{ display: "flex" }}>
                {windowSize.innerWidth < 479 && (
                  <>
                    <Link
                      to="/contact"
                      className={
                        windowSize.innerWidth < 479
                          ? " allArtistsButtons mb-4   "
                          : "talentbutton mr-3"
                      }
                      onClick={() =>
                        addToCartArtistHandler(
                          data1[pages].id,
                          data1[pages].title,
                          true
                        )
                      }
                    >
                      GET ESTIMATE
                    </Link>
                    {!agentInfo._id && (
                      <Link
                        data-w-id="e04f643e-f302-16e2-74ee-4bc7e85391d8"
                        to="#"
                        className={
                          windowSize.innerWidth < 479
                            ? " allArtistsButtons mb-4   "
                            : "talentbutton mr-3"
                        }
                        onClick={() =>
                          addToCartArtistHandler(
                            data1[pages].id,
                            data1[pages].title
                          )
                        }
                        style={{ marginRight: "0px" }}
                      >
                        ADD TO MY LIST
                      </Link>
                    )}
                  </>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <h2 className="h2talent" style={{ marginBottom: "5px" }}>
                  {data1[pages].title}
                </h2>
              </div>
              <div
                className="allArtistsButtonsContainer"
                style={{ width: "100%", marginBottom: "40px" }}
              >
                {/* , 'Published Works', 'Awards', 'Press',"Kid's Portfolio",'Personal Work' */}
                {(() => {
                  // Fetch artistUpdates ONCE outside the map
                  const artistUpdates = agentInfo?.favUpdates?.find(
                    (update) => update.artistId === data1[pages]?.id
                  );

                  return tabsInArtistPortfolio.map((filter, i) => {
                    // Calculate updateCount inside the map for each tab
                    const updateCount = artistUpdates
                      ? filter === "Portfolio"
                        ? artistUpdates.portfolio?.length || 0
                        : filter === "Motion"
                        ? artistUpdates.motions?.length || 0
                        : filter === "Personal Works"
                        ? artistUpdates.personalWorks?.length || 0
                        : filter === "Properties"
                        ? artistUpdates.properties?.length || 0
                        : filter === "Published Works"
                        ? artistUpdates.publishedWorks?.length || 0
                        : filter === "Awards & Press"
                        ? artistUpdates.awardPress?.length || 0
                        : filter === "Bio" && artistUpdates.bio === 1
                        ? 1
                        : 0
                      : 0; // Default to 0 if artistUpdates is undefined
                    if (i === 0) {
                      return (
                        <button
                          key={filter}
                          className={`allArtistsButtons ${
                            searchValue ===
                            filter.replace(/\s+/g, "").toLowerCase()
                              ? "selectedTalentButton"
                              : ""
                          }`}
                          onClick={() =>
                            handleFilterChange(
                              filter,
                              filter === "Bio" && artistUpdates.bio === 1
                            )
                          }
                        >
                          {filter}
                          {updateCount > 0 && (
                            <span
                              style={{
                                display: "inline-flex",
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#d16a27",
                                color: "white",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "50%",
                                marginLeft: "6px",
                              }}
                            >
                              {updateCount}
                            </span>
                          )}
                        </button>
                      );
                    } else if (filter === "KidShannon Portfolio") {
                      if (data1[pages].orderKidArtist !== 100000) {
                        return (
                          <a
                            key={filter}
                            href={`https://kidshannon.com/${data1[pages].fullName}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className="allArtistsButtons">
                              {filter}
                            </button>
                          </a>
                        );
                      }
                      return null;
                    } else {
                      return (
                        <button
                          key={filter}
                          className={`allArtistsButtons ${
                            searchValue ===
                            filter.replace(/\s+/g, "").toLowerCase()
                              ? "selectedTalentButton"
                              : ""
                          }`}
                          onClick={() =>
                            handleFilterChange(
                              filter,
                              filter === "Bio" && artistUpdates?.bio === 1
                            )
                          }
                        >
                          {filter}{" "}
                          {updateCount > 0 && (
                            <span
                              style={{
                                display: "inline-flex",
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "20px",
                                height: "20px",
                                backgroundColor: "#d16a27",
                                color: "white",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "50%",
                                marginLeft: "6px",
                              }}
                            >
                              {updateCount}
                            </span>
                          )}
                        </button>
                      );
                    }
                  });
                })()}
              </div>
              {searchValue === "portfolio" ? (
                <>
                  <div className="mid_content">
                    {JSON.parse(localStorage.getItem("routePaths")) ? (
                      <div
                        className={windowSize.innerWidth < 479 ? "" : "d-flex"}
                        style={{ marginBottom: "10px", width: "98.4%" }}
                      >
                        <div
                          className={"d-flex"}
                          style={{ marginBottom: "10px", width: "98.4%" }}
                        >
                          {JSON.parse(localStorage.getItem("routePaths")).map(
                            (obj, ind) => {
                              if (ind === 2 && mapBipocName(obj.val) !== "") {
                                return (
                                  <p
                                    key={ind}
                                    className={
                                      JSON.parse(
                                        localStorage.getItem("routePaths")
                                      ).length ===
                                      ind + 1
                                        ? "breadCrumbs"
                                        : "breadCrumbsActive"
                                    }
                                    onClick={
                                      JSON.parse(
                                        localStorage.getItem("routePaths")
                                      ).length ===
                                      ind + 1
                                        ? () => {}
                                        : () => history.push(obj.link)
                                    }
                                    style={
                                      ind === 0 ? {} : { marginLeft: "5px" }
                                    }
                                  >
                                    {mapBipocName(obj.val)}{" "}
                                    {JSON.parse(
                                      localStorage.getItem("routePaths")
                                    ).length ===
                                    ind + 1
                                      ? null
                                      : ">"}
                                  </p>
                                );
                              } else {
                                return (
                                  <p
                                    className={
                                      JSON.parse(
                                        localStorage.getItem("routePaths")
                                      ).length ===
                                      ind + 1
                                        ? "breadCrumbs"
                                        : "breadCrumbsActive"
                                    }
                                    onClick={
                                      JSON.parse(
                                        localStorage.getItem("routePaths")
                                      ).length ===
                                      ind + 1
                                        ? () => {}
                                        : () => history.push(obj.link)
                                    }
                                    style={
                                      ind === 0 ? {} : { marginLeft: "5px" }
                                    }
                                  >
                                    {obj.val}{" "}
                                    {JSON.parse(
                                      localStorage.getItem("routePaths")
                                    ).length ===
                                    ind + 1
                                      ? null
                                      : ">"}
                                  </p>
                                );
                              }
                            }
                          )}
                        </div>
                        <div
                          className={
                            windowSize.innerWidth < 479 ? "" : "d-flex"
                          }
                          style={{
                            justifyContent: "space-between",
                            marginTop: "-10px",
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={
                              localStorage.getItem("routePaths")
                                ? { display: "block" }
                                : { display: "flex" }
                            }
                          >
                            {localStorage.getItem("routePaths") &&
                            props.currentBipoc === "none" ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <p
                                  className="linkToKS"
                                  style={{
                                    paddingRight: "10px",
                                    color: "#818181",
                                    fontWeight: "300",
                                    alignSelf: "center",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  See full portfolio
                                </p>
                                <label className="switchToggle">
                                  <input
                                    type="checkbox"
                                    onChange={handleCheckBox}
                                  />
                                  <span className="sliderToggle roundToggle"></span>
                                </label>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {(() => {
                      const artistUpdates = agentInfo?.favUpdates?.find(
                        (update) => update.artistId === data1[pages]?.id
                      );
                      const updatedCount =
                        artistUpdates?.portfolio?.length || 0;
                      if (
                        !agentInfo ||
                        !agentInfo._id ||
                        (updatedCount === 0 && !seePortfolioImages)
                      ) {
                        return null;
                      }
                      return (
                        <button
                          className="allArtistsButtons insideButton"
                          onClick={() =>
                            setSeePortfolioImages(!seePortfolioImages)
                          }
                        >
                          {seePortfolioImages
                            ? "Full Portfolio"
                            : "New Images" + ` (${updatedCount})`}
                        </button>
                      );
                    })()}

                    {windowSize.innerWidth < 479 ? (
                      <div className="tabs-menu w-tab-menu">
                        <div
                          onClick={() => setTab(0)}
                          className={
                            "tabs py-2 w-inline-block w-tab-link flex_center " +
                            (tab === 0
                              ? "bg-white text-black flex_center"
                              : null)
                          }
                        >
                          <div style={{ fontWeight: "600" }}>PORTFOLIO</div>
                        </div>
                        <div
                          onClick={() => setTab(1)}
                          className={
                            "tabs py-2 w-inline-block w-tab-link " +
                            (tab === 1 ? "bg-white  text-black" : null)
                          }
                        >
                          <div style={{ fontWeight: "600" }}>
                            SIMILAR ILLUSTRATORS
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 0 ? (
                      windowSize.innerWidth < 479 ? (
                        <div>
                          <div className="imagecont" style={{ marginTop: 10 }}>
                            {data1[pages].subListData.map((item, keys) => {
                              const favUpdate = agentInfo?.favUpdates?.find(
                                (update) => update?.artistId === data1[pages].id
                              );

                              const isInPortfolio =
                                favUpdate?.portfolio?.includes(item);

                              // ✅ If seePortfolioImages is true and the image is NOT in the updated portfolio, skip rendering
                              if (seePortfolioImages && !isInPortfolio)
                                return null;

                              // ✅ Apply image limit only when seePortfolioImages is false
                              if (!seePortfolioImages && keys >= artistImages)
                                return null;

                              return (
                                <div
                                  key={keys}
                                  className="talentthumbslide resp"
                                  onClick={() => {
                                    isInPortfolio && viewPortfolioImage(keys);
                                    setFullScreenHandler(item);
                                  }}
                                  style={{
                                    border: isInPortfolio
                                      ? "4px solid #d16a27"
                                      : "none",
                                    boxShadow: isInPortfolio
                                      ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                      : "auto",
                                  }}
                                >
                                  <img
                                    src={item}
                                    loading="lazy"
                                    alt=""
                                    className="image"
                                  />
                                </div>
                              );
                            })}
                          </div>
                          {artistImages < data1[pages].subListData.length && (
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                textAlign: "center",
                                margin: "10px",
                                marginTop: 25,
                              }}
                            >
                              <div
                                style={{ fontSize: "16px" }}
                                className="talentbuttonArtistSearch"
                                onClick={() =>
                                  setArtistImages(artistImages + 8)
                                }
                              >
                                See More
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="detail_card w-inline-block ">
                          {!seePortfolioImages
                            ? displayedImages.map((item, key) => {
                                const favUpdate = agentInfo?.favUpdates?.find(
                                  (update) =>
                                    update?.artistId === data1[pages].id
                                );
                                const isInPortfolio =
                                  favUpdate?.portfolio?.includes(item);

                                return (
                                  <div
                                    key={key}
                                    id={"firstSlider" + key}
                                    className="detail_card5_h"
                                    style={{
                                      overflow: "hidden",
                                      height:
                                        windowSize.innerWidth <= 991
                                          ? "8vh"
                                          : "14.5vh",
                                      border: isInPortfolio
                                        ? "4px solid #d16a27"
                                        : "none",
                                      boxShadow: isInPortfolio
                                        ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                        : "auto",
                                    }}
                                    onClick={() => {
                                      isInPortfolio && viewPortfolioImage(key);
                                      setSliderIndexHandler(key);
                                    }}
                                  >
                                    <img
                                      srcSet={item}
                                      className="w-100 h-100"
                                      style={{ objectFit: "cover" }}
                                      alt="Artist Work"
                                      loading="lazy"
                                      fetchpriority={key}
                                      onLoadCapture={() => onImageLoad(key)}
                                    />
                                  </div>
                                );
                              })
                            : // ✅ show only updated images if seePortfolioImages is true
                              data1[pages]?.subListData?.map(
                                (item, originalIndex) => {
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );

                                  const isInPortfolio =
                                    favUpdate?.portfolio?.includes(item);

                                  if (!isInPortfolio) return null;

                                  return (
                                    <div
                                      key={originalIndex}
                                      className="detail_card5_h"
                                      style={{
                                        overflow: "hidden",
                                        height:
                                          windowSize.innerWidth <= 991
                                            ? "8vh"
                                            : "14.5vh",
                                        border: "4px solid #d16a27",
                                        boxShadow:
                                          "0px 2px 6px rgba(0, 0, 0, 0.15)",
                                      }}
                                      onClick={() => {
                                        viewPortfolioImage(originalIndex); // ✅ Now this matches original position
                                        setSliderIndexHandler(originalIndex);
                                      }}
                                    >
                                      <img
                                        srcSet={item}
                                        className="w-100 h-100"
                                        style={{ objectFit: "cover" }}
                                        alt="Updated Work"
                                        loading="lazy"
                                      />
                                    </div>
                                  );
                                }
                              )}

                          {/* Show loading spinner only if seePortfolioImages is false */}
                          {!seePortfolioImages &&
                            data1[pages]?.subListData[
                              displayedImages.length
                            ] && (
                              <div
                                style={{
                                  overflow: "hidden",
                                  height:
                                    windowSize.innerWidth <= 991
                                      ? "8vh"
                                      : "14.5vh",
                                }}
                              >
                                <img
                                  className="mb-3"
                                  alt="loading"
                                  src={loading}
                                />
                              </div>
                            )}
                        </div>
                      )
                    ) : null}
                  </div>
                  <div
                    className="right_content"
                    style={{ position: "relative", zIndex: 1000 }}
                  >
                    <div className="rightside">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        {fullscreen.screen ? (
                          <FullScreenSliderItem
                            onClick={setFullScreenHandler}
                            currentData={data1[pages]}
                            fullscreen={fullscreen}
                          />
                        ) : (
                          <>
                            {data1[pages].slideList.length > 0 &&
                            data1[pages].slideList.length === 1 ? (
                              <div>
                                {data1[pages].slideList.map((item, key) => (
                                  <SliderItems
                                    key={key}
                                    index={key}
                                    sliderIndex={sliderIndex}
                                    src={item}
                                    data1={data1}
                                    search={pages}
                                    windowSize={windowSize}
                                    onClick={setFullScreenHandler}
                                    addToCartArtistHandler={
                                      addToCartArtistHandler
                                    }
                                  />
                                ))}
                              </div>
                            ) : (
                              <SliderShow
                                sliderIndex={sliderIndex}
                                handleSlideChange={handleSlideChange}
                              >
                                {data1[pages].slideList.map((item, key) => (
                                  <SliderItems
                                    key={key}
                                    index={key}
                                    sliderIndex={sliderIndex}
                                    src={item}
                                    data1={data1}
                                    search={pages}
                                    windowSize={windowSize}
                                    onClick={setFullScreenHandler}
                                    addToCartArtistHandler={
                                      addToCartArtistHandler
                                    }
                                  />
                                ))}
                              </SliderShow>
                            )}
                          </>
                        )}
                      </div>
                      {AgentDataAPI.agentData._id && (
                        <>
                          <AddToMoodBoardButton
                            agentData={AgentDataAPI?.agentData}
                            artistImage={data1[pages].slideList[sliderIndex]}
                            artistImageLow={
                              data1[pages].subListData[sliderIndex]
                            }
                            artistID={data1[pages].id}
                          />{" "}
                          <ClientAddToMoodBoardButton
                            clientData={ClientDataAPI?.clientData}
                            artistImage={data1[pages].slideList[sliderIndex]}
                            artistImageLow={
                              data1[pages].subListData[sliderIndex]
                            }
                            artistID={data1[pages].id}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : searchValue === "bio" ? (
                <>
                  {/* Leftside content */}
                  <div className="pl-2 mid_content mid_content_bio">
                    {data1[pages].artistImage ? (
                      <img
                        alt="Artist"
                        className="bioMainImage"
                        src={data1[pages].artistImage}
                      ></img>
                    ) : (
                      <img
                        alt="Artist"
                        className="bioMainImage"
                        src="/assets/images/bioDefaultImage.png"
                      ></img>
                    )}
                    {data1[pages].photoCredit &&
                      data1[pages].photoCredit !== "" && (
                        <div>
                          <p
                            style={{
                              textAlign: "center",
                              marginBottom: "20px",
                              marginTop: "5px",
                              fontWeight: "300",
                              lineHeight: "1.5",
                              fontSize: "0.8rem",
                            }}
                          >
                            Photo Credit : {data1[pages].photoCredit}
                          </p>
                        </div>
                      )}
                    {data1[pages].bipocType !== "None" && (
                      <div>
                        <h3
                          className="homeh3"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          Bipoc
                        </h3>
                        <button
                          className="skillTag"
                          style={{ cursor: "default" }}
                        >
                          {" "}
                          {data1[pages].bipocType}{" "}
                        </button>
                      </div>
                    )}
                    <div className="pronounsContainer">
                      {data1[pages].pronouns.length !== 0 ? (
                        data1[pages].pronouns[0] !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                              marginTop: "50px",
                            }}
                          >
                            Pronouns
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].pronouns.length !== 0
                        ? data1[pages].pronouns?.map((pronoun, i) => {
                            if (pronoun !== "") {
                              return (
                                <button
                                  key={i}
                                  className="skillTag"
                                  style={{ cursor: "default", margin: "0px" }}
                                >
                                  {pronoun}
                                </button>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].socialMedia.length !== 0 ? (
                        data1[pages].socialMedia[0].socialKey !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Social
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].socialMedia.length !== 0
                        ? data1[pages].socialMedia?.map((sM, i) => {
                            if (sM.socialKey !== "") {
                              return (
                                <a
                                  key={i}
                                  href={sM.socialValue}
                                  target="_blanck"
                                  style={{ marginRight: "15px" }}
                                >
                                  <img
                                    alt="Social Media Logo"
                                    style={{ width: "40px", cursor: "pointer" }}
                                    src={
                                      "/assets/images/" +
                                      sM.socialKey +
                                      "BioImage.png"
                                    }
                                  />
                                </a>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].skills.length !== 0 ? (
                        data1[pages].skills[0] !== "" ? (
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Skills
                          </h3>
                        ) : null
                      ) : null}
                      {data1[pages].skills.length !== 0
                        ? data1[pages].skills?.map((skill, i) => {
                            if (skill !== "") {
                              return (
                                <button
                                  key={i}
                                  className="skillTag"
                                  style={{ cursor: "default" }}
                                >
                                  {skill}
                                </button>
                              );
                            }
                            return null;
                          }) || null
                        : null}
                    </div>
                    {data1[pages].videoUrl ? (
                      data1[pages].videoUrl.videoKey === "YouTube" ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Process
                          </h3>
                          <div
                            className="mb-5"
                            style={{
                              position: "relative",
                              display: "inline-block",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                alt="Youtube thumbnail"
                                width="auto"
                                src={
                                  "https://img.youtube.com/vi/" +
                                  getVideoID(data1[pages].videoUrl.videoID) +
                                  "/hqdefault.jpg"
                                }
                              ></img>
                              <button
                                className="playVideoBio"
                                onClick={() => setVideoModal(true)}
                              >
                                &#x2023;
                              </button>
                            </div>
                          </div>
                        </>
                      ) : data1[pages].videoUrl.videoKey === "Vimeo" ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Process
                          </h3>
                          <div
                            className="mb-5"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <div style={{ position: "relative" }}>
                              <img
                                alt="Vimeo thumbnail"
                                width="auto"
                                src={
                                  "https://vumbnail.com/41579/" +
                                  data1[pages].videoUrl.videoID +
                                  ".jpg"
                                }
                              ></img>
                              <button
                                className="playVideoBio"
                                onClick={() => setVideoModal(true)}
                              >
                                &#x2023;
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null
                    ) : null}
                    {videoModal ? (
                      <>
                        <div className="modalTutorial">
                          <div className="containerModal">
                            <div className="modalCloseBtn">
                              <button onClick={() => setVideoModal(false)}>
                                ×
                              </button>
                            </div>
                            <iframe
                              title="Personal Work Video"
                              className="iframeBio"
                              src={data1[pages].videoUrl.videoID}
                            ></iframe>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {/* Rightside content */}
                  <div className="right_content_bio">
                    <h3
                      className="homeh3"
                      style={{ textDecoration: "none", marginBottom: "5px" }}
                    >
                      Biography
                    </h3>
                    <div
                      className="F large"
                      style={{
                        fontSize: "17px",
                        lineHeight: "2",
                      }}
                    >
                      <div
                        className="BioDetailDescriptionPortfolio"
                        dangerouslySetInnerHTML={{
                          __html: data1[pages].detail,
                        }}
                        style={{ marginBottom: "35px" }}
                      ></div>
                    </div>
                    <div className="pronounsContainer">
                      {data1[pages].website.length !== 0 &&
                        data1[pages].website[0] !== "" && (
                          <>
                            <h3
                              className="homeh3"
                              style={{
                                textDecorationLine: "none",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              Website{" "}
                            </h3>
                            {data1[pages].website.map((w, i) => (
                              <a
                                key={i}
                                href={w}
                                target="_blanck"
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                              >
                                <button className="keywordsTag">
                                  {w} <br />
                                </button>
                              </a>
                            ))}
                          </>
                        )}
                    </div>
                    <div>
                      {data1[pages].uniqueKeywords.length !== 0 ? (
                        <>
                          <h3
                            className="homeh3"
                            style={{
                              textDecorationLine: "none",
                              marginBottom: "5px",
                            }}
                          >
                            Category Skills
                          </h3>
                          {data1[pages].uniqueKeywords.map(
                            (categoryKeyword, i) => (
                              <button
                                onClick={(e) => {
                                  portfolioUpdate(categoryKeyword);
                                }}
                                className="keywordsTag"
                                style={{ cursor: "pointer" }}
                              >
                                {categoryKeyword}
                              </button>
                            )
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : searchValue === "publishedworks" ? (
                <>
                  {pwLevel === 1 ? (
                    <>
                      <div style={{ width: "100%" }}>
                        {pwBookPublishing.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3
                                className="homeh3 pwh3"
                                style={{ marginBottom: "0px" }}
                              >
                                Publishing
                              </h3>
                              <div className="separatorPw"></div>
                            </div>
                            <p
                              style={{ color: "#a9a8a8", marginBottom: "20px" }}
                            >
                              Highlighted Projects currently Available For
                              Review - click an image to see more
                            </p>

                            {/* Map through each category dynamically instead of hardcoding each */}
                            {[
                              { title: "Picture Book", data: subPictureBook },
                              { title: "Middle Grade", data: subMiddleGrade },
                              { title: "Young Adult", data: subYoungAdult },
                              { title: "Graphic Novel", data: subGraphicNovel },
                              { title: "Board Book", data: subBoardBook },
                              {
                                title: "Adult Publishing",
                                data: subAdultPublishing,
                              },
                              { title: "Novelty", data: subNovelty },
                              { title: "Box Set", data: subBoxSet },
                              { title: "Game", data: subGame },
                              { title: "Self-Help", data: subSelfHelp },
                            ].map(({ title, data }) =>
                              data.length !== 0 ? (
                                <div key={title}>
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "auto 1fr",
                                    }}
                                  >
                                    <h3 className="homeh3 pwh3sub">{title}</h3>
                                  </div>
                                  <div className="detail_card2 pwCategpryCont">
                                    {data.map((pw, i) => {
                                      // ✅ Find the correct favUpdates entry for the artist
                                      const favUpdate =
                                        agentInfo?.favUpdates?.find(
                                          (update) =>
                                            update?.artistId === data1[pages].id
                                        );

                                      // ✅ Check if this work is in the publishedWorks
                                      const isInPublishedWorks =
                                        favUpdate?.publishedWorks?.some(
                                          (item) => item === pw.id
                                        );
                                      return (
                                        <div
                                          key={i}
                                          onClick={() => {
                                            isInPublishedWorks &&
                                              viewPublishedWorks(pw.id);
                                            handlePWLevelChange(pw);
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            border: isInPublishedWorks
                                              ? "4px solid #d16a27"
                                              : "none",
                                            boxShadow: isInPublishedWorks
                                              ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                              : "auto",
                                          }}
                                        >
                                          <img
                                            src={pw.pwMainImage}
                                            alt={pw.titlePW}
                                          />
                                          <label className="pwLabel">
                                            {pw.titlePW}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : null
                            )}
                          </>
                        )}

                        {pwEditorial.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3 className="homeh3 pwh3">Editorial</h3>
                              <div className="separatorPw"></div>
                            </div>
                            <div className="detail_card2 pwCategpryCont">
                              {pwEditorial
                                .slice()
                                .reverse()
                                .map((pw, i) => {
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );

                                  const isInPublishedWorks =
                                    favUpdate?.publishedWorks?.some(
                                      (item) => item === pw.id
                                    );

                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        isInPublishedWorks &&
                                          viewPublishedWorks(pw.id);
                                        handlePWLevelChange(pw);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        border: isInPublishedWorks
                                          ? "4px solid #d16a27"
                                          : "none",
                                        boxShadow: isInPublishedWorks
                                          ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                          : "auto",
                                      }}
                                    >
                                      <img
                                        src={pw.pwMainImage}
                                        alt={pw.titlePW}
                                      />
                                      <label className="pwLabel">
                                        {pw.titlePW}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}

                        {pwAdvertising.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3 className="homeh3 pwh3">Advertising</h3>
                              <div className="separatorPw"></div>
                            </div>
                            <div className="detail_card2 pwCategpryCont">
                              {pwAdvertising
                                .slice() // ✅ Prevent modifying the original array before reversing
                                .reverse()
                                .map((pw, i) => {
                                  // ✅ Find the correct favUpdates entry for the artist
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );
                                  // ✅ Check if the publishedWorks in favUpdates contains this item based on `_id`
                                  const isInPublishedWorks =
                                    favUpdate?.publishedWorks?.some(
                                      (item) => item === pw.id
                                    );

                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        isInPublishedWorks &&
                                          viewPublishedWorks(pw.id);
                                        handlePWLevelChange(pw);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        border: isInPublishedWorks
                                          ? "4px solid #d16a27"
                                          : "none", // ✅ Highlight if in publishedWorks
                                        boxShadow: isInPublishedWorks
                                          ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                          : "auto", // ✅ Add shadow if in portfolio
                                      }}
                                    >
                                      <img
                                        src={pw.pwMainImage}
                                        alt={pw.titlePW}
                                      />
                                      <label className="pwLabel">
                                        {pw.titlePW}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}
                        {pwMedical.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3 className="homeh3 pwh3">Medical</h3>
                              <div className="separatorPw"></div>
                            </div>
                            <div className="detail_card2 pwCategpryCont">
                              {pwMedical
                                .slice()
                                .reverse()
                                .map((pw, i) => {
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );

                                  const isInPublishedWorks =
                                    favUpdate?.publishedWorks?.some(
                                      (item) => item === pw.id
                                    );

                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        isInPublishedWorks &&
                                          viewPublishedWorks(pw.id);
                                        handlePWLevelChange(pw);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        border: isInPublishedWorks
                                          ? "4px solid #d16a27"
                                          : "none",
                                        boxShadow: isInPublishedWorks
                                          ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                          : "auto",
                                      }}
                                    >
                                      <img
                                        src={pw.pwMainImage}
                                        alt={pw.titlePW}
                                      />
                                      <label className="pwLabel">
                                        {pw.titlePW}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}

                        {pwEntertaiment.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3 className="homeh3 pwh3">Entertainment</h3>
                              <div className="separatorPw"></div>
                            </div>
                            <div className="detail_card2 pwCategpryCont">
                              {pwEntertaiment
                                .slice()
                                .reverse()
                                .map((pw, i) => {
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );

                                  const isInPublishedWorks =
                                    favUpdate?.publishedWorks?.some(
                                      (item) => item === pw.id
                                    );

                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        isInPublishedWorks &&
                                          viewPublishedWorks(pw.id);
                                        handlePWLevelChange(pw);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        border: isInPublishedWorks
                                          ? "4px solid #d16a27"
                                          : "none",
                                        boxShadow: isInPublishedWorks
                                          ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                          : "auto",
                                      }}
                                    >
                                      <img
                                        src={pw.pwMainImage}
                                        alt={pw.titlePW}
                                      />
                                      <label className="pwLabel">
                                        {pw.titlePW}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}

                        {pwOther.length !== 0 && (
                          <>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3 className="homeh3 pwh3">Other</h3>
                              <div className="separatorPw"></div>
                            </div>
                            <div className="detail_card2 pwCategpryCont">
                              {pwOther
                                .slice()
                                .reverse()
                                .map((pw, i) => {
                                  const favUpdate = agentInfo?.favUpdates?.find(
                                    (update) =>
                                      update?.artistId === data1[pages].id
                                  );

                                  const isInPublishedWorks =
                                    favUpdate?.publishedWorks?.some(
                                      (item) => item === pw.id
                                    );

                                  return (
                                    <div
                                      key={i}
                                      onClick={() => {
                                        isInPublishedWorks &&
                                          viewPublishedWorks(pw.id);
                                        handlePWLevelChange(pw);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        border: isInPublishedWorks
                                          ? "4px solid #d16a27"
                                          : "none",
                                        boxShadow: isInPublishedWorks
                                          ? "0px 2px 6px rgba(0, 0, 0, 0.15)"
                                          : "auto",
                                      }}
                                    >
                                      <img
                                        src={pw.pwMainImage}
                                        alt={pw.titlePW}
                                      />
                                      <label className="pwLabel">
                                        {pw.titlePW}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : pwLevel === 2 ? (
                    <>
                      <div className="main-container-awards">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <img
                            onClick={handleBackButtonPublishedWorks}
                            alt="back"
                            src={BackIconForm}
                            style={{ width: "40px", cursor: "pointer" }}
                          />
                        </div>
                        {/* INFO DIV */}
                        <div className="awardsWithImage">
                          <div style={{ position: "relative" }}>
                            <img src={currentPw.pwMainImage} />
                            {AgentDataAPI.agentData._id && (
                              <AddToMoodBoardButton
                                agentData={AgentDataAPI?.agentData}
                                artistImage={currentPw.pwMainImage}
                                artistImageLow={currentPw.pwMainImage}
                                artistID={data1[pages].id}
                              />
                            )}
                          </div>
                          <div className="awardContainer">
                            <h4 className="pwh4 h4CommonStyle">Title:</h4>
                            <h4 className="pwh4 h4CommonStyle">
                              {currentPw.titlePW}
                            </h4>
                            {currentPw.labelAuthorPW !== "" &&
                              currentPw.authorPW !== "" && (
                                <>
                                  <h4 className="pwh4">
                                    {currentPw.labelAuthorPW}:
                                  </h4>
                                  <p className="pwP">{currentPw.authorPW}</p>
                                </>
                              )}
                            <h4 className="pwh4">Illustrator:</h4>
                            <p className="pwP">{currentPw.illustratorPW}</p>
                            <h4 className="pwh4">Client:</h4>
                            <p className="pwP">{currentPw.clientPW}</p>
                            <h4 className="pwh4">Published Date:</h4>
                            <p className="pwP">{currentPw.publishedDatePW}</p>
                            <h4 className="pwh4">Work Contributed:</h4>
                            <p className="pwP">
                              {currentPw.artistContributedPW}
                            </p>
                            {currentPw.pwSecondaryImages.length !== 0 && (
                              <>
                                <p
                                  style={{
                                    color: "#818181",
                                    marginBottom: "0px",
                                    marginTop: "15px",
                                  }}
                                >
                                  See inside
                                </p>
                                <div className="pwSecImgCont">
                                  {currentPw.pwSecondaryImages.length !== 0 &&
                                    currentPw.pwSecondaryImages.map(
                                      (img, i) => {
                                        return (
                                          <div
                                            key={i}
                                            onClick={() => handleCarousel(i)}
                                            style={{
                                              cursor: "pointer",
                                              backgroundImage: `url(${img})`,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              height: "14vh",
                                              backgroundPosition: "center",
                                            }}
                                          ></div>
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            )}
                            {pwModal ? (
                              <>
                                <div className="modalTutorial">
                                  <div className="containerModal2">
                                    <div className="modalCloseBtn">
                                      <button onClick={() => setPwModal(false)}>
                                        ×
                                      </button>
                                    </div>
                                    <div
                                      style={{
                                        maxHeight: "90vh",
                                        display: "flex",
                                        position: "relative",
                                        justifyContent: "space-evenly",
                                        maxWidth: "93%",
                                      }}
                                    >
                                      <img
                                        src="/assets/images/left.png"
                                        alt="leftArrow"
                                        style={{
                                          maxWidth: "40px",

                                          width: "6%",
                                          alignSelf: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => moveCarrousel(-1)}
                                      />
                                      <img
                                        src={
                                          currentPw.pwSecondaryImages[
                                            carrouselIndex
                                          ]
                                        }
                                        alt="secondaryImage"
                                        style={{
                                          maxHeight: "90vh",
                                          maxWidth: "85%",
                                        }}
                                      />
                                      <img
                                        src="/assets/images/right.png"
                                        alt="rightArrow"
                                        style={{
                                          maxWidth: "40px",

                                          width: "6%",
                                          alignSelf: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => moveCarrousel(1)}
                                      />
                                      {AgentDataAPI.agentData._id && (
                                        <AddToMoodBoardButton
                                          agentData={AgentDataAPI?.agentData}
                                          artistImage={
                                            currentPw.pwSecondaryImages[
                                              carrouselIndex
                                            ]
                                          }
                                          artistImageLow={
                                            currentPw.pwSecondaryImages[
                                              carrouselIndex
                                            ]
                                          }
                                          artistID={data1[pages].id}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {/* REVIEWS DIV */}
                        {currentPw.reviewsPW.length !== 0 && (
                          <div>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                              }}
                            >
                              <h3
                                className="homeh3 pwh3"
                                style={{
                                  fontSize: "1.5rem",
                                  marginBottom: "0px",
                                }}
                              >
                                Reviews
                              </h3>
                              <div className="separatorPw"></div>
                            </div>
                            {currentPw.reviewsPW.map((review, i) => {
                              return (
                                <div style={{ margin: "5px 50px 50px 50px" }}>
                                  <div style={{ display: "flex" }}>
                                    {review.isStarReview && (
                                      <img
                                        src="/assets/images/pwStar.svg"
                                        style={{ marginRight: "5px" }}
                                      />
                                    )}
                                    <h4 style={{ fontSize: "1.06rem" }}>
                                      {" "}
                                      {review.descriptiveWords}
                                    </h4>
                                  </div>
                                  <p style={{ color: "#818181" }}>
                                    {" "}
                                    {review.authorOrSoR}
                                  </p>

                                  <p></p>
                                  <div
                                    className="BioDetailDescriptionPortfolio"
                                    dangerouslySetInnerHTML={{
                                      __html: review.quoteReview,
                                    }}
                                    style={{
                                      marginBottom: "35px",
                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {review.link !== "" && (
                                    <p
                                      style={{
                                        color: "#D16A27",
                                        fontSize: "15px",
                                      }}
                                    >
                                      Source / Site:{" "}
                                      <a
                                        href={review.link}
                                        target="_blank"
                                        style={{
                                          color: "#D16A27",
                                          fontSize: "14px",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Click here
                                      </a>
                                    </p>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </>
                  ) : null}
                </>
              ) : searchValue === "personalworks" ? (
                <TabPersonalWorks
                  personalWorks={data1[pages].personalWorks}
                  agentData={AgentDataAPI?.agentData}
                  artistID={data1[pages].id}
                  viewPersonalWorks={viewPersonalWorks}
                />
              ) : searchValue === "properties" ? (
                <TabProperties
                  access={clientDetails}
                  properties={data1[pages].properties.filter(
                    (property) => property.status === "1"
                  )}
                  artist={data1[pages].title}
                  agentData={AgentDataAPI?.agentData}
                  artistID={data1[pages].id}
                  viewTabProperties={viewTabProperties}
                />
              ) : searchValue === "motion" ? (
                <TabMotion
                  motions={data1[pages].motions}
                  agentData={AgentDataAPI?.agentData}
                  artistID={data1[pages].id}
                  viewMotions={viewMotions}
                />
              ) : search === "awards&press" ? (
                <TabAwardPress
                  awardPress={data1[pages].awardPress}
                  agentData={AgentDataAPI?.agentData}
                  artistID={data1[pages].id}
                  viewAwardPress={viewAwardPress}
                />
              ) : null}
              <div
                style={{ width: "100%", backgroundColor: "white", zIndex: "1" }}
              >
                {/* Starting of Similar Artist */}
                {windowSize.innerWidth > 479 ? (
                  <div
                    style={{
                      fontWeight: "700",
                      backgroundColor: "#ce651e",
                      fontSize: "18px",
                      color: "#fff",
                      paddingLeft: "20px",
                      marginTop: "16px",
                      marginBottom: "16px",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                    }}
                    className=" "
                  >
                    CLIENTS WHO VIEWED THIS ARTIST ALSO VIEWED
                  </div>
                ) : null}
                <div>
                  {windowSize.innerWidth < 479 ? (
                    tab === 1 ? (
                      <div>
                        <div className="imagecont" style={{ marginTop: 10 }}>
                          {Object.keys(similarData).length > 0
                            ? Object.keys(similarData).map((key, i) => (
                                <div className="talentthumbslide resp">
                                  <Link
                                    id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                                    data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                                    className="card_img3"
                                    // style={{ position: "relative" }}
                                    to={key + "?image=0"}
                                  >
                                    <div className="detail_card6_h">
                                      <img
                                        src={String(similarData[key].mainImage)}
                                        loading="lazy"
                                        alt=""
                                        className="image"
                                        style={{ width: "100%" }}
                                      />
                                      <div className="artistnamediv">
                                        <div
                                          className="artistnametext-v3"
                                          style={{ padding: "6px 0px" }}
                                        >
                                          {similarData[key].firstname}{" "}
                                          {similarData[key].lastname}
                                        </div>
                                      </div>
                                      {/* <p className="card_img_text3 pb-3 pt-1">
                                      {similarData[key].lastname} {similarData[key].firstname}</p> */}
                                    </div>
                                  </Link>
                                </div>
                              ))
                            : "NO SIMILAR IMAGES FOUND"}
                        </div>
                      </div>
                    ) : null
                  ) : (
                    <div className="detail_card2 my-2">
                      {Object.keys(similarData).length > 0
                        ? Object.keys(similarData).map((key) => (
                            <Link
                              id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                              data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                              className="card_img3"
                              // style={{ position: "relative" }}
                              key={key}
                              to={"../" + key + "?image=0"}
                            >
                              <div className="detail_card6_h">
                                <img
                                  src={String(similarData[key].mainImage)}
                                  loading="lazy"
                                  alt=""
                                  className="image"
                                  style={{ width: "100%" }}
                                />
                                <div className="artistnamediv">
                                  <div
                                    className="artistnametext-v3"
                                    style={{ padding: "6px 0px" }}
                                  >
                                    {similarData[key].firstname}{" "}
                                    {similarData[key].lastname}
                                  </div>
                                </div>
                                {/* <p className="card_img_text3 pb-3 pt-1">
                                {similarData[key].lastname} {similarData[key].firstname}</p> */}
                              </div>
                            </Link>
                          ))
                        : "NO SIMILAR IMAGES FOUND"}
                    </div>
                  )}
                </div>
                {/* ENDING of Similar Artist */}

                {/* Starting of Recently Viewed */}
                {/* Starting of Recently Viewed */}

                {windowSize.innerWidth > 479 ? (
                  <div
                    style={{
                      width: "100%",
                      fontWeight: "700",
                      backgroundColor: "#ce651e",
                      fontSize: "18px",
                      color: "#fff",
                      paddingLeft: "20px",
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                    className="py-2 "
                  >
                    RECENTLY VIEWED
                  </div>
                ) : null}
                {windowSize.innerWidth < 479 ? null : (
                  <div className="detail_card2 my-2">
                    {Object.keys(dataViewed).length > 0
                      ? Object.keys(dataViewed).map((key, i) =>
                          i <= 18 &&
                          dataViewed[key]?.subListData &&
                          dataViewed[key]?.subListData[0] !== "" ? (
                            <Link
                              id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                              data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                              className="card_img3"
                              key={key}
                              to={"../" + key + "?image=0"}
                            >
                              <div className="detail_card6_h">
                                <img
                                  src={String(dataViewed[key]?.subListData[0])}
                                  loading="lazy"
                                  alt=""
                                  className="image"
                                  style={{ width: "100%" }}
                                />
                                <div className="artistnamediv">
                                  <div
                                    className="artistnametext-v3"
                                    style={{ padding: "6px 0px" }}
                                  >
                                    {dataViewed[key].title}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ) : null
                        )
                      : ""}
                  </div>
                )}
                {/* Ending of Recently Viewed */}
              </div>
            </div>
          </>
        ) : (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <img className="mb-3" alt="loading" src={loading} />
          </div>
        )}
      </div>

      {/* END OF ROWS AND COLUMN */}

      <div className="contactpage mt-5 pt-2">
        {isPopupShow && isPopupShowWithCheckbox ? (
          <MyPopup
            BackClose
            onClose={() => {
              saveCartMessage(!isCheckboxChecked);
              setIsPopupShow(false);
            }}
          >
            <div className="mx-5 my-4">
              <div>{msg}</div>
              <div
                class="form-check form-switch mt-2"
                style={{ display: "flex" }}
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  style={{ cursor: "pointer", accentColor: "#BC6127" }}
                  checked={isCheckboxChecked}
                  onClick={() => {
                    setIsCheckboxChecked(!isCheckboxChecked);
                  }}
                />
                <label
                  class="form-check-label"
                  for="flexSwitchCheckDefault"
                  style={{ paddingTop: "5px" }}
                >
                  Do not show this again
                </label>
              </div>
            </div>
            <div
              className="cartBadgeSearchArtist"
              onClick={() => {
                saveCartMessage(!isCheckboxChecked);
                setIsPopupShow(false);
              }}
            >
              x
            </div>
          </MyPopup>
        ) : isPopupShow && !isPopupShowWithCheckbox ? (
          <MyPopup
            BackClose
            onClose={() => {
              setIsPopupShowWithCheckbox(true);
              setIsPopupShow(false);
            }}
          >
            <div className="mx-5 my-4">
              <div>{msg}</div>
            </div>
            <div
              className="cartBadgeSearchArtist"
              onClick={() => {
                saveCartMessage(!isCheckboxChecked);
                setIsPopupShow(false);
              }}
            >
              x
            </div>
          </MyPopup>
        ) : null}
      </div>
    </div>
  );
}

export default SearchByArtist;
